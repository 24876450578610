import lottie from 'lottie-react-web'
import { rgba } from 'polished'
import styled from 'styled-components'

import { animated } from 'react-spring'

export const Container = styled(animated.div)`
  position: relative;

  width: 100%;
  max-width: 900px;
  height: 100%;
  max-height: 63vw;
  margin: auto;

  /* overflow: hidden; */

  box-shadow: rgb(0 0 0 / 25%) 0px 0px 20px 20px;
  border-radius: 10px;

  cursor: pointer;

  .overlay {
    position: absolute;
    left: 50%;
    width: inherit;
    height: 100%;
    margin-top: 10%;

    /* transform: translateX(-50%); */

    background: linear-gradient(
      180deg,
      transparent,
      ${props => rgba(props.theme.colors.background.normal, 0.9)} 50%,
      ${props => props.theme.colors.background.normal}
    );
  }
  img {
    box-shadow: none;
  }
`

export const Lottie = styled(lottie).attrs(() => ({
  style: {
    position: 'absolute',
    width: '50%',
    height: 'auto',
    marginTop: '30%',

    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 5

    // filter: 'opacity(0.5) invert(1) grayscale(1) brightness(0.3)'
  }
}))``
