import React from 'react'

import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { IconType } from 'react-icons'
import { Container } from './styles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'button' | 'submit' | 'reset'
  text: string
  icon?: IconType
}

function Button({
  type = 'button',
  text,
  icon = HiOutlineArrowNarrowRight,
  ...props
}: Props) {
  return (
    <Container type={type} className="button" {...props}>
      {text}
      {icon({})}
    </Container>
  )
}

export default Button
