import Home from '@/assets/icons/home.svg'
import About from '@/assets/icons/about.svg'
import projects from '@/assets/icons/projects.svg'
import proposals from '@/assets/icons/proposals.svg'

const routes = [
  {
    route: '/',
    icon: Home
  },
  {
    route: '/about',
    icon: About
  },
  // {
  //   route: '/#2',
  //   icon: FiAward
  // },
  {
    route: '/projects',
    icon: projects
  },
  {
    route: '/proposal',
    icon: proposals
  }
]

export default routes
