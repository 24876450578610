export const Slider1 = [
  '/images/mockups/mobile-ecoleta-1.svg',
  '/images/mockups/mobile-ecoleta-2.svg',
  '/images/mockups/mobile-ecoleta-3.svg',
  '/images/mockups/mobile-ecoleta-4.svg'
]

export const Slider2 = [
  '/images/mockups/plantManager-1.svg',
  '/images/mockups/plantManager-2.svg',
  '/images/mockups/plantManager-3.svg',
  '/images/mockups/plantManager-4.svg',
  '/images/mockups/plantManager-5.svg',
  '/images/mockups/plantManager-6.svg',
  '/images/mockups/plantManager-8.svg',
  '/images/mockups/plantManager-9.svg',
  '/images/mockups/plantManager-10.svg'
]

export const Slider3 = [
  '/images/mockups/plantManager-dark-1.svg',
  '/images/mockups/plantManager-dark-2.svg',
  '/images/mockups/plantManager-dark-3.svg',
  '/images/mockups/plantManager-dark-4.svg',
  '/images/mockups/plantManager-dark-5.svg',
  '/images/mockups/plantManager-dark-6.svg',
  '/images/mockups/plantManager-dark-8.svg',
  '/images/mockups/plantManager-dark-9.svg',
  '/images/mockups/plantManager-dark-10.svg'
]
