import styled, { css } from 'styled-components'
import { rgba } from 'polished'

export const Container = styled.nav`
  ${({ theme }) => {
    const height = '60px'
    return css`
      position: fixed;
      bottom: 0;

      width: 100%;

      z-index: 15;

      /* $width: 270px; */
      /* $height: 60px; */
      /* $active: #5628ee;
    $inactive: #a8b2c9;
    $hover: #626c82;
    $background: #fff; */

      background: ${rgba(theme.colors.background.dark, 0.8)};
      backdrop-filter: blur(5px);
      border-radius: 6px;
      box-shadow: 0 4px 12px -1px rgba(#121621, 0.1);

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        position: relative;
        z-index: 1;
        li {
          position: relative;
          flex-grow: 1;
          &.active {
            a {
              z-index: 5;
              div {
                span {
                  &:last-child {
                    width: 20px;
                  }
                }
              }
            }
          }
          a {
            cursor: pointer;
            display: table;
            position: relative;
            display: flex;
            z-index: 1;
            justify-content: center;
            align-items: center;
            height: ${height};
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            div,
            span,
            svg {
              width: 20px;
              height: 20px;
              display: block;
              -webkit-backface-visibility: hidden;
            }
            div {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              span {
                top: 0;
                left: 0;
                transform-origin: 50% 50%;
                position: absolute;
                overflow: hidden;
                z-index: 1;
                /* background: ${theme.colors.background.dark}; */
                &:first-child {
                  width: 20px;
                  svg {
                    transition: fill 0.3s ease, stroke 0.3s ease;
                    /* fill: ${theme.colors.primary.normal}; */
                    stroke: ${theme.colors.primary.normal};
                  }
                }
                &:last-child {
                  width: 0;
                  z-index: 5;
                  transition: width 0.25s ease;
                  svg {
                    /* fill: ${theme.colors.secondary}; */
                    stroke: ${theme.colors.secondary};
                  }
                }
              }
            }
            &:hover {
              div {
                span {
                  &:first-child {
                    svg {
                      /* fill: ${theme.colors.primary.dark}; */
                      stroke: ${theme.colors.primary.dark};
                    }
                  }
                }
              }
            }
            &.toRight {
              div {
                span {
                  &:last-child {
                    width: 20px;
                    transition: width 0.25s ease 0.25s;
                    animation: pulse 0.3s linear forwards 0.35s;
                  }
                }
              }
            }
            &.toLeft {
              div {
                span {
                  &:last-child {
                    left: auto;
                    right: 0;
                    width: 20px;
                    transition: width 0.25s ease 0.25s;
                    animation: pulse 0.3s linear forwards 0.35s;
                    svg {
                      position: absolute;
                      top: 0;
                      right: 0;
                    }
                  }
                }
              }
            }
            &.removeLeft {
              div {
                span {
                  &:last-child {
                    width: 0;
                    transition: width 0.2s ease;
                  }
                }
              }
            }
            &.removeRight {
              div {
                span {
                  &:last-child {
                    left: auto;
                    right: 0;
                    width: 0;
                    transition: width 0.2s ease;
                    svg {
                      position: absolute;
                      top: 0;
                      right: 0;
                    }
                  }
                }
              }
            }
          }
          em {
            --offset: 0;
            border-radius: 50%;
            display: block;
            width: 6px;
            height: 6px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -3px 0 0 -3px;
            z-index: 4;
            transition: transform 0.5s ease, margin 0.3s ease;
            &:before {
              content: '';
              width: inherit;
              height: inherit;
              border-radius: inherit;
              display: block;
              background: ${theme.colors.secondary};
              transform: scale(0);
            }
            &.move {
              &:before {
                animation: showDot 0.4s linear forwards;
              }
            }
            &.up {
              &.move {
                transform: translateX(var(--offset));
                &.multipleSteps {
                  animation: moveTop 0.4s linear forwards;
                }
              }
            }
            &.up2 {
              width: 4px;
              height: 4px;
              &.move {
                margin-left: -8px;
                transform: translateX(var(--offset)) translateY(4px);
                &.multipleSteps {
                  animation: moveTop 0.4s linear forwards;
                }
              }
            }
            &.up2 {
              width: 3px;
              height: 3px;
              &.move {
                margin-left: -10px;
                transform: translateX(var(--offset)) translateY(-2px);
                &.multipleSteps {
                  animation: moveTop 0.4s linear forwards;
                }
              }
            }
            &.down {
              &.move {
                transform: translateX(var(--offset));
                &.multipleSteps {
                  animation: moveDown 0.4s linear forwards;
                }
              }
            }
            &.down2 {
              width: 4px;
              height: 4px;
              &.move {
                margin-left: -10px;
                transform: translateX(var(--offset)) translateY(-5px);
                &.multipleSteps {
                  animation: moveDown 0.4s linear forwards;
                }
              }
            }
            &.down3 {
              width: 3px;
              height: 3px;
              &.move {
                margin-left: -7px;
                transform: translateX(var(--offset)) translateY(3px);
                &.multipleSteps {
                  animation: moveDown 0.4s linear forwards;
                }
              }
            }
          }
        }
      }

      @keyframes pulse {
        50% {
          transform: scale(1.15);
        }
      }

      @keyframes moveTop {
        50% {
          top: 10%;
        }
      }

      @keyframes moveDown {
        50% {
          top: 90%;
        }
      }

      @keyframes showDot {
        50% {
          transform: scale(1);
        }
        5%,
        85% {
          transform: scale(0);
        }
      }
    `
  }}
`
