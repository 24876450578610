import { Router } from 'next/router'
import React, { createContext, useState, useEffect, useContext } from 'react'

import SideBar from '@/components/SideBar'
import BottomTabBar from '@/components/BottomTabBar'

interface GlobalContextData {
  NavBar: {
    activated: boolean
    set(prop: boolean): void
  }
}

const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData)

export const GlobalProvider: React.FC = ({ children }) => {
  const [enableNavBar, setEnableNavBar] = useState(true)

  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [])

  const handleRouteChangeComplete = () => {
    document.querySelector('#layout').scrollTo(0, 0)
  }

  const NavBar = {
    activated: enableNavBar,
    set: (prop: boolean) => {
      !prop
        ? document.querySelector('#__next').classList.add('navbar-false')
        : document.querySelector('#__next').classList.remove('navbar-false')

      setEnableNavBar(prop)
    }
  }

  return (
    <GlobalContext.Provider value={{ NavBar }}>
      {enableNavBar && (
        <>
          <SideBar />
          <BottomTabBar />
        </>
      )}
      {children}
    </GlobalContext.Provider>
  )
}

// Hook próprio
export function useGlobal(): GlobalContextData {
  const context = useContext(GlobalContext)

  return context
}
