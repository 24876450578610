import { useRef, useState } from 'react'

import { useSpring, animated, config } from 'react-spring'

import clickAnimation from '@/assets/animations/click.json'

import { Container, Lottie } from './styles'
import Image from 'next/image'

interface ImageModalProps {
  alt: string
  src: string
}

function ImageModal(props: ImageModalProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const [open, set] = useState(false)

  const { transform, ...rest } = useSpring({
    config: config.stiff,
    from: { overflow: 'hidden' },
    to: {
      transform: `scale(${open ? 0 : 1}) translateX(-50%)`,
      overflow: open ? 'auto' : 'hidden'
    }
  })

  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: clickAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  function handleClick() {
    const container = containerRef.current
    console.log(container.scrollTop)
    container.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    set(open => !open)
  }

  return (
    <Container ref={containerRef} style={{ ...rest }} onClick={handleClick}>
      {!open && <Lottie options={defaultAnimationOptions} />}

      <animated.div
        ref={overlayRef}
        className="overlay"
        style={{ transform }}
      />
      <img {...props} />
    </Container>
  )
}

export default ImageModal
